var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rightsCommentForm"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"姓名","prop":"name"}},[_c('v-input',{attrs:{"placeholder":"请输入姓名"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"职务","prop":"post"}},[_c('v-input',{attrs:{"placeholder":"请输入职务"},model:{value:(_vm.form.post),callback:function ($$v) {_vm.$set(_vm.form, "post", $$v)},expression:"form.post"}})],1),_c('el-form-item',{attrs:{"label":"图片","rules":[
          {
            required: true,
            message: '请上传图片',
            trigger: ['change', 'blur'],
          },
        ],"prop":"picture"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.picture},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "picture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "picture", $event)}}})],1),_c('el-form-item',{attrs:{"label":"评议等级","prop":"assessmentGrade"}},[_c('v-select',{attrs:{"clearable":"","placeholder":"评议等级","options":_vm.commentGrade},model:{value:(_vm.form.assessmentGrade),callback:function ($$v) {_vm.$set(_vm.form, "assessmentGrade", $$v)},expression:"form.assessmentGrade"}})],1),_c('el-form-item',{attrs:{"label":"评议类别","prop":"assessmentType"}},[_c('v-select',{attrs:{"clearable":"","placeholder":"评议类别","options":_vm.commentKinds},model:{value:(_vm.form.assessmentType),callback:function ($$v) {_vm.$set(_vm.form, "assessmentType", $$v)},expression:"form.assessmentType"}})],1),_c('el-form-item',{attrs:{"label":"排序","rules":[{ required: true, message: '请输入排序值', trigger: 'blur' }],"prop":"sortIndex"}},[_c('v-input',{attrs:{"placeholder":"请输入排序值","width":250,"type":"number"},model:{value:(_vm.form.sortIndex),callback:function ($$v) {_vm.$set(_vm.form, "sortIndex", $$v)},expression:"form.sortIndex"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }