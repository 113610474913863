<template>
  <div class="rightsCommentForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item label="姓名" prop="name">
        <v-input placeholder="请输入姓名" v-model="form.name"></v-input>
      </el-form-item>
      <el-form-item label="职务" prop="post">
        <v-input placeholder="请输入职务" v-model="form.post"></v-input>
      </el-form-item>
      <el-form-item
        label="图片"
        :rules="[
          {
            required: true,
            message: '请上传图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="picture"
      >
        <v-upload :limit="1" :imgUrls.sync="form.picture" />
      </el-form-item>
      <el-form-item label="评议等级" prop="assessmentGrade">
        <v-select
          clearable
          placeholder="评议等级"
          :options="commentGrade"
          v-model="form.assessmentGrade"
        />
      </el-form-item>
      <el-form-item label="评议类别" prop="assessmentType">
        <v-select
          clearable
          placeholder="评议类别"
          :options="commentKinds"
          v-model="form.assessmentType"
        />
      </el-form-item>
      <el-form-item
        label="排序"
        :rules="[{ required: true, message: '请输入排序值', trigger: 'blur' }]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入排序值"
          v-model="form.sortIndex"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { mobileRegular } from "@/utils/regular";
import { rightsCommentDetailUrl, editRightsCommentUrl } from "./api.js";
import {
  commentGrade,
  commentGradeMap,
  commentKindsMap,
  commentKinds,
} from "./map.js";
export default {
  name: "partyMemberForm",
  data() {
    return {
      commentKinds,
      commentGrade,
      width: 250,
      submitConfig: {
        queryUrl: rightsCommentDetailUrl,
        submitUrl: editRightsCommentUrl,
      },

      form: {
        id: "",
        name: "",
        post: "",
        assessmentGrade: "",
        assessmentType: "",
        sortIndex: 1,
        picture: "",
      },
    };
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    this.form.id = id;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
      this.handleRoomUrl();
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    change() {},
    validatePhone(rule, value, callback) {
      let regExp = mobileRegular;
      if (!regExp.test(value)) {
        callback(new Error("您填写手机号码格式不正确"));
      } else {
        callback();
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.rightsCommentForm {
  box-sizing: border-box;
  height: 100%;
}
</style>
<style lang="less"></style>
